<template>
	<nav class="navbar" style="position:relative; top:0px; right:0px; z-index:999;">
		<div class="container-fluid">
			<div class="dropdown text-end" v-if="IS_LOGIN">
				{{USER_INFO.NAME}}
				<a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
					<img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" class="rounded-circle">
				</a>
				<ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
					<li><a class="dropdown-item" href="#" @click="accountInfo()">Profile</a></li>
					<li><hr class="dropdown-divider"></li>
					<li><a class="dropdown-item" href="#" @click="signOut()">Sign Out</a></li>
				</ul>
			</div>
		</div>
	</nav>
	<router-view />
</template>
<script>
	import "@/assets/css/layout.css";
	import "@/assets/css/contents.css";
	import { $session } from "dot-framework";
	import store from "@/store/index.js";

	export default {

		data() {
			return {
				IS_LOGIN: false, // 로그인 여부
				USER_INFO: {} // 사용자 정보
			}
		},
		mounted()
        {
            // 로그인 정보 표시
            this.setLoginInfo();
        },
		components: {
		},
		methods: {
			setLoginInfo()
            {
				this.IS_LOGIN = $session.isLogin();
                if (this.IS_LOGIN) {
                    // JWT 파싱해서 이름 조회
                    this.USER_INFO = $session.getUserInfo();
                }
            },
			// 로그아웃
            signOut()
            {
                $session.signOut();
				this.IS_LOGIN = false;
				store.dispatch("setMenuList");
				window.location.href = $session.getSignInUrl();
            },
		}
	}
</script>

<style scoped>
.navbar {
	float:right;
	display:inline;
	background: rgba(1,1,1,0.25);
	border-radius: 10px 10px 10px 10px/10px 10px 10px 10px;
}
</style>
